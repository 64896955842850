<div class="maincontainer" (clickOutside)="cancel($event)">
  <div class="w-100 headerSection">
    <span class="headerTitle">Assign Users</span>

    <div class="btn bg-blue ml-auto headerCloseBtn" (click)="cancel('close')">
      <i class="icon icon-black icon-xl icon-action_remove"></i>
    </div>
  </div>

  <div class="contentcontainer">
    <section class="w-100 formSection">
      <div class="row w-100 mx-0 py-2 form-head">
        <span>Select Users To Assign</span>
      </div>

      <div class="row w-100 mx-0 pt-3 tableDiv">
        <aecom-table
          class="asignUserTable"
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list"
          [canSearch]="true"
          [columnFilters]="columnFilters"
        >
          <thead>
            <tr>
              <th class="checkbox">
                <aecom-column-selector (ngValueChange)="checkBoxChange()"></aecom-column-selector>
              </th>

              <ng-container *ngFor="let hCol of tableHeader">
                <th *ngIf="hCol.Type !== columnType.Hide" class="sort assignUser_th" id="{{ hCol.Name }}">
                  {{ hCol.Header }}
                </th>
              </ng-container>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let bRow of tableRows" class="assignUser_tr_hover">
              <td class="checkbox">
                <aecom-checkbox [(ngValue)]="bRow.checked" (ngValueChange)="checkBoxChange()"></aecom-checkbox>
              </td>

              <ng-container *ngFor="let hCol of tableHeader">
                <td *ngIf="hCol.Type !== columnType.Hide">
                  <span class="assignUser_td_Item">{{ bRow[hCol.Name] }}</span>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </aecom-table>
      </div>

      <div class="row w-100 mx-0 py-2 form-head mt-5">
        <span>Assign Permission ({{ selectedUserNumber }})</span>
      </div>

      <div class="row w-100 mx-0 py-2">
        <div class="roleBlock row mx-0">
          <ng-container *ngFor="let app of applicationList; let i = index">
            <div class="col-sm-3 roleLabel py-2" [ngClass]="i % 2 === 0 ? 'pl-0 pr-3' : 'px-3'">
              <span>{{ app.name }}</span>
            </div>

            <div class="col-sm-3 py-2" [ngClass]="i % 2 === 0 ? 'px-3' : 'pl-4 pr-0'">
              <aecom-dropdown
                [theme]="'light'"
                [placeholder]="'Select Role'"
                [data]="app.dropdowns"
                (ngValueChange)="onRoleChange($event, i)"
                [selectedId]="getDefault(app)"
              ></aecom-dropdown>
            </div>
          </ng-container>

          <div class="col-sm-3 roleLabel py-2" [ngClass]="applicationList.length % 2 === 0 ? 'pl-0 pr-3' : 'px-3'">
            <span>Contract</span>
          </div>

          <div class="col-sm-3 py-2" [ngClass]="applicationList.length % 2 === 0 ? 'px-3' : 'pl-4 pr-0'">
            <aecom-dropdown
              [theme]="'light'"
              [placeholder]="'Select Role'"
              [data]="contractRoles"
              (ngValueChange)="onContractRoleChange($event)"
              [selectedId]="selectContractRole"
            ></aecom-dropdown>
          </div>
        </div>
      </div>
    </section>

    <section class="buttonSection">
      <div class="d-flex row mx-0 pt-2" style="line-height: 48px">
        <div class="ml-auto" style="display: flex; align-items: center">
          <aecom-button innerClass="btn orange btn-icon outline ml-auto" class="px-2" (click)="cancel('close')">
            Cancel
          </aecom-button>

          <!-- <aecom-button *ngIf="contract"  innerClass="btn orange btn-icon solid" class="px-2" icon="icon-social_user_ban" (click)="disable()"> Disable </aecom-button> -->

          <aecom-button
            id="saveButton"
            [disabled]="!activeSave"
            [innerClass]="activeSave ? 'btn blue btn-icon solid' : 'btn bg-grey-d btn-icon solid disabled'"
            class="px-2"
            (click)="onSubmit()"
          >
            <span [ngStyle]="{ color: activeSave ? 'black' : '#8c8279' }"> Assign </span>
          </aecom-button>

          <!-- <aecom-button *ngIf="!contract" innerClass="btn blue btn-icon solid" class="px-2" (click)="contractEditForm.ngSubmit.emit()"> Add Contract </aecom-button> -->
        </div>
      </div>
    </section>
  </div>
</div>
