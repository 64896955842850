import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export default class LoadingService {
  constructor(private spinner: NgxSpinnerService) {}

  start(): void {
    this.spinner.show("spinner");
  }

  stop(): void {
    this.spinner.hide("spinner");
  }

  // isLoading(d: any[]) {
  //   // console.log('loading',d);
  //   if (d == undefined) {
  //     this.start;
  //   } else {
  //     this.stop;
  //   }
  // }
}
