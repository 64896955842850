import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ContractUserService, UserService } from "../api-generated";
import IContractUserWithUserInfo from "../models/IContractUserWithUserInfo";
import LoadingService from "../services/loading.service";
import LocalContractUserService from "../services/local-contractUser.service";
import LocalUserService from "../services/local-user.service";
import LocalUserRoleService from "../services/local-userRole.service";
import RouteParams from "../shared/route-params";

@Injectable()
export default class ContractUserResolver
  implements Resolve<IContractUserWithUserInfo[] | null>
{
  constructor(
    private contractUserService: ContractUserService,
    public localUserService: LocalUserService,
    private userService: UserService,
    public localUserRoleService: LocalUserRoleService,
    public localContractUserService: LocalContractUserService,
    private loadingService: LoadingService,
    private router: Router,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<IContractUserWithUserInfo[] | null> {
    this.loadingService.start();
    const urlLinkId = route.params[RouteParams.ItemId];
    const localUsers = this.localUserService.getItems();

    return forkJoin([
      this.userService.getRoles(),
      this.contractUserService.getContractUser(urlLinkId),
      localUsers && localUsers.length > 0
        ? Promise.resolve(localUsers)
        : this.userService.getAll('old'),
    ]).pipe(
      map(([roles, contractUser, users]) => {
        if (!localUsers || localUsers.length === 0) {
          this.localUserService.setItems(users);
        }
        this.localContractUserService.setItems(contractUser);
        this.localUserRoleService.setItems(roles);
        return contractUser.map((cu) => {
          const user = users.find((u) => {
            return u.id === cu.UserId;
          });
          const role = roles.find((r) => {
            return (
              r.azureId === cu.UserId &&
              r.appRole.includes(cu.contract?.Code ?? "unknow")
            );
          });
          let contractRole: string;
          if (role) {
            const roleArray = role.appRole.split("_");
            contractRole = roleArray.pop();
          }
          return { ...cu, UserInfo: user, ContractRole: contractRole };
        });
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        // this.router.navigate(["error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }
}
