/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import { IMenuItem, ITableFilter, ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractUserService } from "src/app/api-generated";
import { IPContractUserApplicationRoleCreateItem } from "src/app/api-generated/model/iPContractUserApplicationRoleCreateItem";
import { IPContractUserCreateItem } from "src/app/api-generated/model/iPContractUserCreateItem";
import { TPContract } from "src/app/api-generated/model/tPContract";
import ColumnType from "src/app/models/columnType";
import IContractUserWithUserInfo from "src/app/models/IContractUserWithUserInfo";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationService from "src/app/services/local-application.service";
import LocalApplicationRoleService from "src/app/services/local-applicationRole.service";
import LocalUserService from "src/app/services/local-user.service";
import TableHeaderCol from "./table-header-col";
import RowItem from "./table-row";
import { DB_Admin, DB_User } from "src/app/shared/staticValue";

interface ApplicationDropDown {
  id: string;
  name: string;
  dropdowns: IMenuItem[];
  value: string;
}

@Component({
  selector: "app-contract-assign-user",
  templateUrl: "./contract-assignUser.component.html",
  styleUrls: ["./contract-assignUser.component.css"]
})
export default class ContractAssignUserComponent implements OnInit {
  @Input() contract: TPContract;

  @Output() close = new EventEmitter<void>();

  @Input() firstOpen: boolean;

  @Output() firstOpenChange = new EventEmitter<boolean>();

  applicationList: ApplicationDropDown[] = [];

  selectedUserNumber = 0;

  public tableData: RowItem[] = [];

  public tableRows: RowItem[] = [];

  contractRoles: IMenuItem[] = [
    { id: "User", name: "User", checked: false },
    { id: "Admin", name: "Admin", checked: false }
  ];

  selectContractRole = "User";

  public tableHeader: TableHeaderCol[] = [
    {
      Name: "id",
      Type: "hide",
      Header: "User #",
      RelativeWidth: 0
    },
    {
      Name: "name",
      Type: "text",
      Header: "Name",
      RelativeWidth: 150
    },
    {
      Name: "email",
      Type: "text",
      Header: "Email",
      RelativeWidth: 150
    },
    {
      Name: "organization",
      Type: "text",
      Header: "Organization",
      RelativeWidth: 100
    },
    {
      Name: "track",
      Type: "text",
      Header: "Track",
      RelativeWidth: 50
    }
  ];

  public columnFilters: ITableFilter[] = [
    {
      column: "organization",
      name: "Organization",
      multiselect: false
    },
    {
      column: "track",
      name: "Track",
      multiselect: false
    }
  ];

  columnType = ContractAssignUserComponent.columnType;

  @Input() activeSave: boolean;

  @Output() activeSaveChange = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private applicationService: LocalApplicationService,
    private loadingService: LoadingService,
    private activeModal: ModalContainerService,
    private contractUserService: ContractUserService,
    private applicationRoleService: LocalApplicationRoleService,
    private userService: LocalUserService,
    private activatedRoute: ActivatedRoute
  ) {}

  static get columnType(): typeof ColumnType {
    return ColumnType;
  }

  ngOnInit(): void {
    const contractUsers: IContractUserWithUserInfo[] =
      this.activatedRoute.snapshot.data.contractUserData;

    const contractUserIdArray = contractUsers.map((user) => user.UserId);

    const listedUser = this.userService.getItems().filter((user) => !contractUserIdArray.includes(user.id));

    listedUser.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

    this.tableData = listedUser.map((user) => {
      return {
        id: user.id,
        name: user.displayName,
        email: user.mail,
        organization:
          user.department ? user.department.split(" ")[0] : "",
        track: user.department,
        checked: false
      };
    });

    // console.log("userList", this.tableData);

    const applicationRoles = this.applicationRoleService.getItems();

    const applications = this.applicationService
      .getItems()
      .filter((app) => this.contract.contract_application.map((c) => c.ApplicationId).includes(app.Guid));

    applications.sort((a, b) => a.Order - b.Order);

    this.applicationList = applications.map((a) => {
      const roles = applicationRoles.filter((role) => role.ApplicationId === a.Guid);

      return {
        id: a.Guid,
        name: a.Name,
        value: "",
        dropdowns: roles.length
          ? roles.map((role) => {
              return { id: role.Guid, name: role.Role.replace(DB_Admin, DB_User), checked: false };
            })
          : [...this.contractRoles]
      };
    });
  }

  onSubmit(): void {
    if (!this.activeSave) {
      return;
    }

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to save the changes?";
    modalInstance.instance.theme = "light";

    this.firstOpenChange.emit(true);

    modalInstance.result.then(async (result) => {
      if (result === 1) {
        // this.activeSaveChange.emit(false);
        this.loadingService.start();

        const applicationRoles = [];
        const contractUsers: IPContractUserCreateItem[] = [];
        const selectedUser = this.tableData.filter((row) => row.checked);

        this.applicationList.forEach((app) => {
          const appRole = {
            ApplicationId: app.id,
            ApplicationRoleId: app.dropdowns.length ? app.dropdowns.find((d) => d.id === app.value).id : app.value
          } as IPContractUserApplicationRoleCreateItem;

          applicationRoles.push(appRole);
        });

        // console.log(applicationRoles);

        selectedUser.forEach((user) => {
          const contract = {} as IPContractUserCreateItem;

          contract.UserId = user.id;
          contract.ContractRole = this.selectContractRole;
          contract.ContractId = this.contract.Guid;

          contract.ApplicationRoles = applicationRoles.filter((a) => a.ApplicationRoleId !== "");

          contractUsers.push(contract);
        });

        console.log("contractUsers to update", JSON.parse(JSON.stringify(contractUsers)));

        this.contractUserService.assignContractUsers(contractUsers).subscribe(
          (users) => {
            if (users) {
              this.back();
            }
          },
          (error: HttpErrorResponse) => {
            this.errorHandle(error);
          }
        );
      }
    });
  }

  back(): void {
    this.router.navigate([`/contractUser/${this.contract.Guid}`]);
  }

  cancel(event: any): void {
    const classList = event?.targetElement?.classList ? event.targetElement?.classList : [];

    if (classList.length && classList.value.includes("clear-selected")) {
      return;
    }

    this.close.emit();
  }

  onRoleChange(e: IMenuItem | IMenuItem[], i: number): void {
    // console.log("onRoleChange", e);

    if (e && !Array.isArray(e) && this.applicationList[i].value !== e.id) {
      this.applicationList[i].value = e.id;
    }

    // console.log(this.applicationList);
  }

  onContractRoleChange(e: IMenuItem | IMenuItem[]): void {
    // console.log("onContractRoleChange", e);

    if (e && !Array.isArray(e) && this.selectContractRole !== e.id) {
      this.selectContractRole = e.id;
    }

    // console.log(this.selectContractRole);
  }

  checkBoxChange(): void {
    // console.log("userList", this.tableData);

    this.selectedUserNumber = this.tableData.filter((row) => row.checked).length;

    if (this.selectedUserNumber > 0) {
      this.activeSaveChange.emit(true);
    }
  }

  getDefault(app: ApplicationDropDown): string {
    if (app.value === "") {
      app.value = app.dropdowns.find((item) => item.name.toLocaleLowerCase() === "user").id;
    }

    return app.value;
  }

  errorHandle(error: HttpErrorResponse): void {
    this.loadingService.stop();
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Your Changes Can't be Saved.";
    modalInstance.instance.body = "Error occurs. Please refresh your screen to see updates.";
    modalInstance.result.then((result) => {
      if (result === 0) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl("blank").then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      }
    });
  }
}
