/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import {
  IMenuItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractUserService } from "src/app/api-generated";
import { IPContractUserApplicationRoleCreateItem } from "src/app/api-generated/model/iPContractUserApplicationRoleCreateItem";
import { IPContractUserCreateItem } from "src/app/api-generated/model/iPContractUserCreateItem";
import { TPApplicationRole } from "src/app/api-generated/model/tPApplicationRole";
import { TPContract } from "src/app/api-generated/model/tPContract";
import ContractUserCreateItem from "src/app/models/contractUserCreateItem";
import IContractUserWithUserInfo from "src/app/models/IContractUserWithUserInfo";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationService from "src/app/services/local-application.service";
import LocalApplicationRoleService from "src/app/services/local-applicationRole.service";
import {
  DBUserRole,
  DDCUserRole,
  NoAccess,
  SAUserRole,
  correspondenceAppId,
  rfiAppId,
  submittalAppId,
  transmittalAppId,
} from "src/app/shared/staticValue";
import LocalUserRoleService from "src/app/services/local-userRole.service";
import { getOrganizationRoles } from "src/app/shared/utils";
import RowItem from "../contractUser-summary/table-row";

interface ApplicationDropDown {
  id: string;
  name: string;
  dropdowns: IMenuItem[];
  value: string;
}

@Component({
  selector: "app-contract-manage-user",
  templateUrl: "./contract-manageUser.component.html",
  styleUrls: ["./contract-manageUser.component.css"],
})
export default class ContractManageUserComponent implements OnInit {
  @Input() selectedUsers: RowItem[];

  @Input() contract: TPContract;

  @Output() close = new EventEmitter<void>();

  @Input() firstOpen: boolean;

  @Output() firstOpenChange = new EventEmitter<boolean>();

  applicationList: ApplicationDropDown[] = [];

  contractRoles: IMenuItem[] = [];

  selectContractRole = "";

  multipleUsers = false;

  @Input() activeSave: boolean;

  @Output() activeSaveChange = new EventEmitter<boolean>();

  private mixed = "Mixed";

  private allContractUsers: IContractUserWithUserInfo[];

  public disabledContract = false;

  roleList: TPApplicationRole[] = [];

  constructor(
    private router: Router,
    private applicationService: LocalApplicationService,
    private loadingService: LoadingService,
    private activeModal: ModalContainerService,
    private contractUserService: ContractUserService,
    private applicationRoleService: LocalApplicationRoleService,
    private activatedRoute: ActivatedRoute,
    public localUserRoleService: LocalUserRoleService,
  ) {}

  ngOnInit(): void {
    const contractUsers: IContractUserWithUserInfo[] =
      this.activatedRoute.snapshot.data.contractUserData;

    this.multipleUsers = this.selectedUsers.length > 1;
    const allRoles = this.localUserRoleService.getItems();
    const selectedUsersRoles = this.selectedUsers.map((u) => {
      const uRoles = allRoles
        .filter((r) => {
          return r.azureId === u.userId;
        })
        .map((r) => {
          return r.appRole;
        });
      if (uRoles.includes(DBUserRole)) {
        return "db";
      }
      if (uRoles.includes(SAUserRole)) {
        return "sa";
      }
      if (
        uRoles.includes(DDCUserRole) ||
        u.organizations.toLocaleLowerCase() === "ddc"
      ) {
        return "ddc";
      }
      return "ahjv";
    });
    const uniqueUserRoles = [...new Set(selectedUsersRoles)];
    this.selectContractRole = "";

    this.roleList = this.applicationRoleService.getItems();

    this.allContractUsers = contractUsers;

    const applications = this.applicationService
      .getItems()
      .filter((app) =>
        this.contract.contract_application
          .map((c) => c.ApplicationId)
          .includes(app.Guid),
      );

    applications.sort((a, b) => a.Order - b.Order);

    this.contractRoles = [
      { id: "User", name: "User", checked: false },
      { id: "Admin", name: "Admin", checked: false },
    ];

    this.applicationList = applications.map((a) => {
      const roles = this.roleList.filter(
        (role) => role.ApplicationId === a.Guid,
      );

      return {
        id: a.Guid,
        name: a.Name,
        value: "",
        dropdowns: roles.length
          ? getOrganizationRoles(uniqueUserRoles, roles)
          : [...this.contractRoles],
      };
    });

    console.log(this.applicationList);

    this.selectedUsers.forEach((user) => {
      if (this.selectContractRole === "") {
        this.selectContractRole = user.contract;
      } else if (
        this.selectContractRole !== user.contract &&
        this.selectContractRole !== this.mixed
      ) {
        this.contractRoles.push({
          id: this.mixed,
          name: this.mixed,
          checked: false,
          isUnselectable: true,
        });

        this.selectContractRole = this.mixed;
      }

      this.applicationList.forEach((application) => {
        let value = "";
        switch (application.id) {
          case rfiAppId:
            const rfiRole = application.dropdowns.find((item) => {
              return (
                item.name ===
                (user.rfi && user.rfi.trim() !== ""
                  ? user.rfi
                  : NoAccess)
              );
            });
            if (rfiRole) {
              value = rfiRole.id;
            } else {
              value = user.rfi;
            }
            break;
          case submittalAppId:
            const submittalRole = application.dropdowns.find((item) => {
              return (
                item.name ===
                (user.submittal && user.submittal.trim() !== ""
                  ? user.submittal
                  : NoAccess)
              );
            });
            if (submittalRole) {
              value = submittalRole.id;
            } else {
              value = user.submittal;
            }
            break;
          case correspondenceAppId:
            const corrrespondenceRole = application.dropdowns.find((item) => {
              return (
                item.name ===
                (user.correspondence && user.correspondence.trim() !== ""
                  ? user.correspondence
                  : NoAccess)
              );
            });
            if (corrrespondenceRole) {
              value = corrrespondenceRole.id;
            } else {
              value = user.correspondence;
            }
            break;
          case transmittalAppId:
            const transmittalRole = application.dropdowns.find((item) => {
              return (
                item.name ===
                (user.transmittal && user.transmittal.trim() !== ""
                  ? user.transmittal
                  : NoAccess)
              );
            });
            if (transmittalRole) {
              value = transmittalRole.id;
            } else {
              value = user.transmittal;
            }
            break;
          default:
            break;
        }

        if (application && application.value === "") {
          application.value = value;
        } else if (
          application.value !== value &&
          application.value !== this.mixed
        ) {
          application.dropdowns.push({
            id: this.mixed,
            name: this.mixed,
            checked: false,
            isUnselectable: true,
          });

          application.value = this.mixed;
        }
      });
    });

    console.log(this.applicationList);
  }

  onSubmit(): void {
    if (!this.activeSave) {
      return;
    }

    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.title = "Notification";
    modalInstance.instance.body =
      "Are you sure you would like to save the changes?";
    modalInstance.instance.theme = "light";
    this.firstOpenChange.emit(true);

    modalInstance.result.then(async (result) => {
      if (result === 1) {
        this.loadingService.start();

        if (this.disabledContract) {
          const ids = this.selectedUsers.map((user) => {
            return user.id;
          });

          this.contractUserService.removeContractUsers(ids).subscribe(
            () => {
              this.back();
            },
            (error: HttpErrorResponse) => {
              this.errorHandle(error);
            },
          );
        } else {
          const contractUsers: IPContractUserCreateItem[] = [];
          const applicationRoles: IPContractUserApplicationRoleCreateItem[] =
            JSON.parse(
              JSON.stringify(
                this.applicationList.map((app) => {
                  return {
                    ApplicationId: app.id,
                    ApplicationRoleId: app.dropdowns.length
                      ? app.dropdowns.find((d) => d.id === app.value).id
                      : app.value,
                  } as IPContractUserApplicationRoleCreateItem;
                }),
              ),
            );

          console.log("applicationRoles", applicationRoles);
          console.log("allContractUsers", this.allContractUsers);

          this.selectedUsers.forEach((user) => {
            const currentContractUser = this.allContractUsers.find((u) => {
              return u.Guid === user.id;
            });

            if (currentContractUser) {
              const appRoles = applicationRoles.map((r) => {
                if (r.ApplicationRoleId === "Mixed") {
                  const prevId =
                    currentContractUser.contract_user_application_role.find(
                      (ar) => {
                        return ar.ApplicationId === r.ApplicationId;
                      },
                    );
                  return { ...r, ApplicationRoleId: prevId?.ApplicationRoleId };
                }
                return r;
              });

              const editContractUser = new ContractUserCreateItem(
                currentContractUser.UserId,
                currentContractUser.ContractId,
                currentContractUser.ContractRole,
                appRoles,
                currentContractUser.Guid,
              );

              if (this.selectContractRole !== this.mixed) {
                editContractUser.ContractRole = this.selectContractRole;
              }

              console.log(
                "editContractUser ApplicationRoles",
                editContractUser.ApplicationRoles,
              );

              contractUsers.push(editContractUser);
            }
          });
          console.log(contractUsers);

          this.contractUserService.assignContractUsers(contractUsers).subscribe(
            (users) => {
              if (users) {
                this.back();
              }
            },
            (error: HttpErrorResponse) => {
              this.errorHandle(error);
            },
          );
        }
      }
    });
  }

  back(): void {
    this.router.navigate([`/contractUser/${this.contract.Guid}`]);
  }

  cancel(): void {
    this.close.emit();
  }

  onRoleChange(e: IMenuItem | IMenuItem[], i: number): void {
    if (e && !Array.isArray(e) && this.applicationList[i].value !== e.id) {
      this.applicationList[i].value = e.id;
      this.activeSaveChange.emit(true);
    }
  }

  onContractRoleChange(e: IMenuItem | IMenuItem[]): void {
    if (e && !Array.isArray(e) && this.selectContractRole !== e.id) {
      this.selectContractRole = e.id;
      this.activeSaveChange.emit(true);
    }
  }

  removeUser(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body =
      "Are you sure you would like to remove this permission?";
    this.firstOpenChange.emit(true);
    modalInstance.result.then((result) => {
      if (result === 1) {
        this.disabledContract = true;
        this.activeSaveChange.emit(true);
      }
    });
  }

  errorHandle(error: HttpErrorResponse): void {
    this.loadingService.stop();
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Your Changes Can't be Saved.";
    modalInstance.instance.body =
      "Error occurs. Please refresh your screen to see updates.";
    modalInstance.result.then((result) => {
      if (result === 0) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl("blank").then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      }
    });
  }
}
