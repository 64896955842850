/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-object-injection */
import {
  ITableFilter,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TPContract } from "src/app/api-generated/model/tPContract";
import ColumnType from "src/app/models/columnType";
import LoginUser from "src/app/models/loginUser";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationService from "src/app/services/local-application.service";
import LocalBoroughService from "src/app/services/local-borough.service";
import LocalContractService from "src/app/services/local-contract.service";
import AuthService from "../../auth/auth.service";
import TableHeaderCol from "./table-header-col";
import RowItem from "./table-row";

@Component({
  selector: "app-contract-summary",
  templateUrl: "./contract-summary.component.html",
  styleUrls: ["./contract-summary.component.css"],
})
export default class ContractSummaryComponent implements OnInit, AfterViewInit {
  public currentUser: LoginUser;

  public tableData: RowItem[] = [];

  public tableRows: RowItem[] = [];

  public tableHeader: TableHeaderCol[] = [
    {
      Name: "id",
      Type: "hide",
      Header: "Contract #",
      RelativeWidth: 0,
    },
    {
      Name: "icon",
      Type: "boroughIcon",
      Header: "",
      RelativeWidth: 50,
    },
    {
      Name: "name",
      Type: "text",
      Header: "Contract Name",
      RelativeWidth: 200,
    },
    {
      Name: "borough",
      Type: "text",
      Header: "Borough",
      RelativeWidth: 150,
    },
    {
      Name: "modules",
      Type: "text",
      Header: "Modules",
      RelativeWidth: 200,
    },
    {
      Name: "numberOfUsers",
      Type: "text",
      Header: "# Of Users",
      RelativeWidth: 50,
    },
    {
      Name: "status",
      Type: "text",
      Header: "Status",
      RelativeWidth: 50,
    },
  ];

  public columnFilters: ITableFilter[] = [
    {
      column: "borough",
      name: "Borough",
      multiselect: false,
    },
    {
      column: "status",
      name: "Status",
      multiselect: false,
    },
  ];

  public contracts: TPContract[] = [];

  public selectContract: TPContract = null;

  public showEditContract = false;

  public firstOpen = true;

  public activeSave = false;

  columnType = ContractSummaryComponent.columnType;

  getBoroughIcon = ContractSummaryComponent.getBoroughIcon;

  isGlobalAdmin = false;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    public authService: AuthService,
    private contractService: LocalContractService,
    private boroughService: LocalBoroughService,
    private applicationService: LocalApplicationService,
    private activeModal: ModalContainerService,
  ) {}

  static get columnType(): typeof ColumnType {
    return ColumnType;
  }

  static getBoroughIcon(icon: string): string {
    return `assets/icons/${icon}`;
  }

  ngOnInit(): void {
    this.currentUser = this.authService.user;
    // this.isGlobalAdmin = this.currentUser.Permissions.includes(globalAdmin);
    this.isGlobalAdmin = true;
    this.contracts = this.contractService.getItems();
    if (!this.isGlobalAdmin) {
      const permissionsArray = this.currentUser.Permissions.split(",").filter(
        (s) => {
          return s.includes("Admin");
        },
      );
      if (permissionsArray.length > 0) {
        const adminPermission = permissionsArray.join(",");
        this.contracts = this.contracts.filter((c) => {
          return adminPermission.includes(c.Code ?? "unknow");
        });
      }
    }
    this.contracts = this.contracts
      .filter((c) => {
        return c.BoroughId;
      })
      .sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      );

    const boroughs = this.boroughService.getItems();
    const applications = this.applicationService.getItems();

    this.contracts.forEach((value) => {
      let boroughName = "";
      let boroughIcon = "";
      let moduleNames = "";

      const boroughIndex = boroughs.findIndex((b) => {
        return b.Code.toUpperCase() === value.borough.Code.toUpperCase();
      });

      if (boroughIndex >= 0) {
        boroughName = boroughs[boroughIndex].Name;
        boroughIcon = boroughs[boroughIndex].Icon;
      }

      if (value.contract_application.length) {
        const appIds = value.contract_application.map((c) => c.ApplicationId);

        appIds.forEach((id) => {
          const appIndex = applications.findIndex((a) => a.Guid === id);

          if (appIndex >= 0) {
            moduleNames += `${applications[appIndex].Name}, `;
          }
        });

        if (moduleNames !== "") {
          moduleNames = moduleNames.slice(0, -2);
        }
      }

      this.tableData.push(
        new RowItem(
          false,
          value.Guid,
          value.Name,
          boroughIcon,
          boroughName,
          moduleNames,
          value.contract_user ? value.contract_user.length.toString() : "0",
          value.InActive === true ? "Disabled" : "Active",
        ),
      );
    });

    // this.contracts.push(new ContractViewModel('all','',contractList,false));
  }

  ngAfterViewInit(): void {
    this.loadingService.stop();
  }

  createContract(): void {
    this.router.navigateByUrl("/contracts/new");
  }

  contractSettingClick(row: RowItem): void {
    this.router.navigateByUrl(`contracts/${row.id}`);
  }

  contractClick(row: RowItem): void {
    this.router.navigateByUrl(`contractUser/${row.id}`);
  }

  closeEditContractWindow(): void {
    if (this.firstOpen) {
      this.firstOpen = false;
    } else if (!this.activeSave) {
      this.firstOpen = true;
      this.showEditContract = false;
    } else {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      const modalInstance = this.activeModal.open(NotificationComponent);

      modalInstance.instance.title = "Notification";
      modalInstance.instance.body = "Are you sure you would like to cancel?";
      modalInstance.instance.theme = "light";

      modalInstance.result.then(async (result) => {
        this.firstOpen = true;

        if (result === 1) {
          this.showEditContract = false;
        }
      });
    }
  }
}
