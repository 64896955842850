import { IMenuItem } from "@aecom/core";
import { TPApplicationRole } from "../api-generated/model/tPApplicationRole";
import { DB_Admin, DB_User, NoAccess } from "./staticValue";

/**
 *
 * @param organization AHJV, DDC or DB
 * @param roles PMC Doc Control, PMC Manager, PMC Coordinator, DDC Representative, User and DB Admin
 * @returns a list of roles bades on the organization
 */

export function getOrganizationRoles(
  organization: string[],
  roles: TPApplicationRole[],
): IMenuItem[] {
  let filteredRoles = [...roles];
  organization.forEach((org)=>{
    filteredRoles = filterRoles(org, filteredRoles);
  })
  return filteredRoles.map((role) => {
    return { id: role.Guid, name: role.Role === DB_Admin ? DB_User : role.Role, checked: false };
  });
}

function filterRoles(organization: string, roles: TPApplicationRole[]):TPApplicationRole[] {
  const pmcUser = "User";
  const DBUser = "DB Admin";
  const SAUser = "SA User";
  const DDC = "DDC";
  const AHJVAccess = [pmcUser, NoAccess];
  const DBAccess = [DBUser, NoAccess];
  const SAAccess = [SAUser, NoAccess];
  switch (organization) {
    case "ahjv":
      return roles
        .filter(
          (r) =>
            (!r.Role.includes(DDC) &&
            !r.Role.includes(SAUser)) ||
            AHJVAccess.includes(r.Role)
        );
    case "ddc":
      return roles
        .filter((r) => r.Role.includes(DDC) || AHJVAccess.includes(r.Role));
    case "db":
      return roles
        .filter((r) => DBAccess.includes(r.Role));
    case "sa":
      return roles
        .filter((r) => SAAccess.includes(r.Role));
    default:
      return roles;
  }
}
