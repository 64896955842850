/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import {
  AzureAdService,
  IMenuItem,
  ISelectItem,
  ITableFilter,
  ModalContainerService,
  NotificationComponent,
  NotificationType
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractUserService, EmailService, IUser } from "src/app/api-generated";
import { IPContractUserCreateItem } from "src/app/api-generated/model/iPContractUserCreateItem";
import { TPContract } from "src/app/api-generated/model/tPContract";
import ColumnType from "src/app/models/columnType";
import ContractUser from "src/app/models/contractUser";
import IContractUserWithUserInfo from "src/app/models/IContractUserWithUserInfo";
import LoginUser from "src/app/models/loginUser";
import { User, UserInformation } from "src/app/models/user";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationRoleService from "src/app/services/local-applicationRole.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import LocalUserService from "src/app/services/local-user.service";
import { globalAdmin } from "src/app/shared/staticValue";
import environment from "src/environments/environment";
import AuthService from "../../auth/auth.service";

class TableHeaderCol {
  public Name: string;

  public Type: string;

  public Header: string;

  public RelativeWidth: number;
}

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"]
})
export default class UserListComponent implements OnInit {
  assignMultipleUsers = false;

  assignSingleUser = false;

  isLoaded = false;

  currentUser: LoginUser;

  oldUserInformation = new UserInformation("", "", "", "", "", "", "", "", "", [new ContractUser("", "")]);

  userInformation = new UserInformation("", "", "", "", "", "", "", "", "", [new ContractUser("", "")]);

  multiUserContract = new ContractUser("", "");

  contractListDropdown: ISelectItem[] = [];

  userTypeListDropdown: ISelectItem[] = [];

  contractRoles: ISelectItem[] = [
    { id: "User", name: "User", checked: false },
    { id: "Admin", name: "Admin", checked: false }
  ];

  contractList: TPContract[] = [];

  userList: IUser[] = [];

  contractUsers: IContractUserWithUserInfo[] = [];

  deleteContractUsers: IContractUserWithUserInfo[] = [];

  columnType = UserListComponent.columnType;

  tableData: User[] = [];

  tableRows: User[] = [];

  tableHeader: TableHeaderCol[] = [
    {
      Name: "id",
      Type: "hide",
      Header: "User Id",
      RelativeWidth: 0
    },
    {
      Name: "name",
      Type: "text",
      Header: "Name",
      RelativeWidth: 200
    },
    {
      Name: "email",
      Type: "text",
      Header: "email",
      RelativeWidth: 150
    },
    {
      Name: "organization",
      Type: "text",
      Header: "organization",
      RelativeWidth: 200
    },
    {
      Name: "track",
      Type: "text",
      Header: "track",
      RelativeWidth: 200
    },
    {
      Name: "accountEnable",
      Type: "text",
      Header: "account Enabled",
      RelativeWidth: 200
    },
    {
      Name: "contract",
      Type: "text",
      Header: "contract",
      RelativeWidth: 200
    },
    {
      Name: "edit",
      Type: "text",
      Header: "",
      RelativeWidth: 50
    }
  ];

  columnFilters: ITableFilter[] = [
    {
      column: "organization",
      name: "Organization",
      multiselect: false
    },

    {
      column: "track",
      name: "Track",
      multiselect: false
    },

    {
      column: "contract",
      name: "Contract",
      multiselect: false,
      separate: ", ",
    }
  ];

  statusDropdown: ISelectItem[] = [
    { checked: false, id: "0", name: "Disabled" },
    { checked: false, id: "1", name: "Active" }
  ];

  isGlobalAdmin = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public loadingService: LoadingService,
    public userService: LocalUserService,
    public azureAdService: AzureAdService,
    public contractService: LocalContractService,
    public activeModal: ModalContainerService,
    public userContractService: LocalContractUserService,
    public applicationRoleService: LocalApplicationRoleService,
    private contractUserService: ContractUserService,
    private emailService: EmailService
  ) {}

  // ngAfterContentChecked(): void {
  //   this.isLoaded = true;
  // }

  static get columnType(): typeof ColumnType {
    return ColumnType;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.authService.user;
    // console.log("currentUser", this.currentUser);
    this.isGlobalAdmin = this.currentUser.Permissions.includes(globalAdmin);

    const appRoles = this.applicationRoleService.getItems();
    this.userTypeListDropdown = appRoles.map((role)=>{return {
      checked: false,
      id: role.Guid,
      name: role.Role
    }});

    const contracts = this.contractService.getItems();
    this.contractListDropdown = contracts.map((c)=>{return {
      checked: false,
      id: c.Guid,
      name: c.Name
    }});

    this.contractUsers = this.activatedRoute.snapshot.data.userData;

    this.tableData = [];
    this.userList = this.userService.getItems();
    
    this.userList.forEach((user: IUser) => {
      const cts = this.contractUsers.filter((us) =>{return us.UserId === user.id});
      const temp = user.department?.split(" ") || [];
      const organizationName = temp[0] || "";

      this.tableData.push({
        id: user.id,
        name: user.displayName,
        email: user.mail,
        organization: organizationName,
        company: user.companyName,
        contract: cts.map((c)=>{return c.contract?.Name ?? ""}).join(", "),
        track: user.department,
        department: user.department,
        checked: false,
        accountEnable: this.getAccountEnable(user)
      });
    });

    console.log(this.tableData);

    this.loadingService.stop();
  }

  assignUser(id: string): void {
    console.log("assignUser", id);

    this.userInformation = new UserInformation("", "", "", "", "", "", "", "", "", [new ContractUser("", "")]);

    const tempUser = this.userList.find((u) => u.id === id);
    const contracts = this.contractUsers.filter((u) => u.UserId === tempUser.id);

    // this.userRoles = this.userTypeListDropdown.filter(r => r.)

    const temp = tempUser.department?.split(" ") || [];
    const organizationName = temp[0] || "";

    this.userInformation.id = tempUser.id;
    this.userInformation.name = tempUser.displayName;
    this.userInformation.email = tempUser.mail;
    this.userInformation.organization = organizationName;
    this.userInformation.company = tempUser.companyName;
    this.userInformation.track = tempUser.department;
    this.userInformation.jobTitle = tempUser.jobTitle;
    this.userInformation.jobManager = tempUser.jobTitle;
    this.userInformation.status = tempUser.accountEnabled ? "1" : "0";

    if (contracts.length) {
      this.userInformation.contractList = contracts;
    }

    console.log("c: contracts", contracts);
    console.log("c: userInformation contractList", JSON.parse(JSON.stringify(this.userInformation.contractList)));

    console.log("c: contractListDropdown", this.contractListDropdown);
    console.log("c: userTypeListDropdown", this.userTypeListDropdown);

    this.oldUserInformation = JSON.parse(JSON.stringify(this.userInformation));
    this.assignSingleUser = true;
  }

  assignUsers(): void {
    if (!this.canAssign()) {
      return;
    }

    this.assignMultipleUsers = true;

    console.log("assignUsers");
  }

  getUsers(): number {
    return this.tableData.filter((r) => r.checked).length;
  }

  canAssign(): boolean {
    return this.tableData.filter((r) => r.checked).length > 0;
  }

  onboardUser(): void {
    window.open(`${environment.url.portal}/onboarding`);
  }

  resendDaildEmail(): void {
    let modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Action Confirmation?";
    modalInstance.instance.body = "Do you want to resend daily emails?";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.start();
        this.emailService.sendDaliyReminderEmail().subscribe(
          (res) => {
            console.log("email sent", res);
            this.loadingService.stop();
            modalInstance = this.activeModal.open(NotificationComponent);
            modalInstance.instance.type = NotificationType.Information;
            modalInstance.instance.theme = "light";
            modalInstance.instance.title = "Confirmed";
            modalInstance.instance.body = "Daily Emails have been sent successfully.";
            modalInstance.result;
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop();
            modalInstance = this.activeModal.open(NotificationComponent);
            modalInstance.instance.type = NotificationType.Information;
            modalInstance.instance.theme = "light";
            modalInstance.instance.title = "Error";
            modalInstance.instance.body = "Error occurs.";
            modalInstance.result;
          }
        );
      }
    });
    
  }

  assignContract(id: string): void {
    console.log("assignContract", id);
  }

  setContract(contract: ContractUser, e: ISelectItem): void {
    if (e === null || Array.isArray(e)) {
      return;
    }

    contract.ContractId = e.id;
    console.log("c: setContract", e);
  }

  setContractRole(contract: ContractUser, e: ISelectItem): void {
    if (e === null || Array.isArray(e)) {
      return;
    }

    contract.ContractRole = e.id;

    console.log("c: setContractRole", e);
    console.log("c: userInformation", this.userInformation);
  }

  updateUserStatus(e: IMenuItem): void {
    if (e === null || Array.isArray(e)) {
      return;
    }

    console.log("updateUserStatus", e);
    this.userInformation.status = e.id;
  }

  addMoreContracts(): void {
    console.log("addMoreContracts");

    this.userInformation.contractList.push(new ContractUser(this.userInformation.id, ""));
  }

  deleteUserContract(e: ContractUser): void {
    if (e === null || Array.isArray(e)) {
      return;
    }

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to remove this permission?";

    modalInstance.result.then((result) => {
      if (result === 1) {
        if (e.Guid) {
          e.DateDeleted = new Date().toLocaleDateString();
          e.DeletedBy = this.currentUser.Id;
        } else {
          this.userInformation.contractList.pop();
        }
      }
    });
  }

  cancel(): void {
    if (!this.hasChanges()) {
      this.assignMultipleUsers = false;
      this.assignSingleUser = false;
      this.userInformation = null;
      this.oldUserInformation = null;
      this.multiUserContract = new ContractUser("", "");
      return;
    }

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to cancel?";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.assignMultipleUsers = false;
        this.assignSingleUser = false;
        this.userInformation = null;
        this.oldUserInformation = null;
        this.multiUserContract = new ContractUser("", "");
        console.log("canceled");
      }
    });
  }

  save(): void {
    if (!this.hasChanges()) {
      return;
    }

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to save the changes?";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.start();

        const UserId = this.userInformation.id;

        const contracts: IPContractUserCreateItem[] = this.userInformation.contractList.map((c) => {
          return {
            UserId,
            ContractId: c.ContractId,
            ContractRole: c.ContractRole,
            Guid: c.Guid || undefined,
            DeletedBy: c.DeletedBy ? c.DeletedBy : undefined,
            DateDeleted: c.DateDeleted ? new Date().toISOString() : undefined,
            ApplicationRoles: []
          } as IPContractUserCreateItem;
        });
        const updates = contracts.filter((c)=>!c.DeletedBy);
        const deletes = contracts.filter((c)=>c.DeletedBy);
        if(updates.length > 0)
        {
          this.contractUserService.assignContractUsers(contracts).subscribe(
            (res) => {
              console.log("c: save - assignContractUsers", res);
  
              this.assignMultipleUsers = false;
              this.assignSingleUser = false;
              this.router.navigateByUrl("/users");
            },
            (error: HttpErrorResponse) => {
              this.errorHandle(error);
            }
          );
        }
        if(deletes.length > 0)
        {
          this.contractUserService.removeContractUsers(deletes.map((d)=>d.Guid)).subscribe(
            (res) => {
              console.log("c: save - removeContractUsers", res);
  
              this.assignMultipleUsers = false;
              this.assignSingleUser = false;
              this.router.navigateByUrl("/users");
            },
            (error: HttpErrorResponse) => {
              this.errorHandle(error);
            }
          );
        }
      }
    });
  }

  saveMultipleUsers(): void {
    /**
     * As an admin, I would like to add users to the contract.
     *   If more than one user is selected, "ADD TO CONTRACT" button will get activated.
     *   If clicked the "Assign to contract button", the panel will slide in from right.
     *      Show number of selected users if multiple
     *      Dropdown input for contract and role.
     *      Only allow assigning to a single contract and one role for selected users.
     *   Same interaction for save and cancel from the previous feature
     */

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to save the changes?";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.start();

        const contracts: IPContractUserCreateItem[] = this.tableData
          .filter((r) => r.checked)
          .map((u: User) => {
            return {
              UserId: u.id,
              ContractId: this.multiUserContract.ContractId,
              ContractRole: this.multiUserContract.ContractRole,
              Guid: undefined,
              ApplicationRoles: []
            } as IPContractUserCreateItem;
          });

        console.log("c: save - contracts", contracts);

        this.contractUserService.assignContractUsers(contracts).subscribe(
          (res) => {
            console.log("c: save - assignContractUsers", res);

            this.assignMultipleUsers = false;
            this.assignSingleUser = false;
            this.router.navigateByUrl("/users");
          },
          (error: HttpErrorResponse) => {
            this.errorHandle(error);
          }
        );
      }
    });
  }

  getUserName(user: IUser): string {
    let firstName = user.givenName;
    let lastName = user.surname;

    if (user.surname === null || user.givenName == null) {
      if (user.displayName.includes(",")) {
        const fullName = user.displayName.split(", ");

        [lastName, firstName] = fullName;
      } else {
        const email = user.mail.split("@")[0];
        const fullName = email.split(".");

        if (email.includes(".")) {
          [firstName, lastName] = fullName;
        } else {
          firstName = "??";
          lastName = "??";
        }
      }
    }

    return `${firstName} ${lastName}`;
  }

  getAccountEnable(user: IUser): string {
    if(user.accountEnabled === true)
    {
      return "Yes";
    }
    if(user.accountEnabled === false) {
      return "No";
    }
    return "";
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldUserInformation);
    const currentRFI = JSON.stringify(this.userInformation);

    const oldMultiUserContract = JSON.stringify(new ContractUser("", ""));
    const currentMultiUserContract = JSON.stringify(this.multiUserContract);

    // true means no changes
    return old !== currentRFI || oldMultiUserContract !== currentMultiUserContract;
  }

  errorHandle(error: HttpErrorResponse): void {
    this.loadingService.stop();
    const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.type = NotificationType.Information;
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Your Changes Can't be Saved.";
      modalInstance.instance.body = "Error occurs. Please refresh your screen to see updates.";
      modalInstance.result.then((result) => {
        if (result === 0) {
          const currentUrl = this.router.url;
          this.router.navigateByUrl("blank").then(() => {
            this.router.navigateByUrl(currentUrl);
          });
        }
    });
  }
}
