/* eslint-disable security/detect-object-injection */
/* eslint-disable prefer-destructuring */
import {
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
  recursiveDelete
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractService } from "src/app/api-generated";
import { Borough } from "src/app/api-generated/model/borough";
import { TPContract } from "src/app/api-generated/model/tPContract";
import Contract from "src/app/models/contract";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationService from "src/app/services/local-application.service";
import LocalBoroughService from "src/app/services/local-borough.service";
import LocalContractService from "src/app/services/local-contract.service";
import RouteParams from "src/app/shared/route-params";
import { docModuleId } from "src/app/shared/staticValue";

@Component({
  selector: "app-contract-create",
  templateUrl: "./contract-create.component.html",
  styleUrls: ["./contract-create.component.scss"]
})
export default class ContractCreateComponent implements OnInit {
  contract: Contract;

  oldContract: Contract;

  submitted = false;

  itemId: string = null;

  contractList: TPContract[];

  boroughList: ISelectItem[];

  boroughs: Borough[];

  contractExist = false;

  code = "";

  codeExist = false;

  codeValid = true;

  applicationList: ISelectItem[];

  today: Date;

  ntpDate: Date;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private contractService: ContractService,
    public activeModal: ModalContainerService,
    private localBoroughService: LocalBoroughService,
    private localContractService: LocalContractService,
    private localApplicationService: LocalApplicationService
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.today = new Date();
    this.today.setDate(new Date().getDate() + 1);

    const urlLinkId = this.activatedRoute.snapshot.params[RouteParams.ItemId];
    this.boroughs = this.localBoroughService.getItems();

    this.boroughList = this.boroughs.map((b) => {
      return { id: b.Guid, name: b.Name, checked: false };
    });

    const applications = this.localApplicationService.getItems().filter((app) => {
      return app.ModuleId === docModuleId;
    });

    applications.sort((a, b) => a.Order - b.Order);

    this.applicationList = applications.map((a) => {
      return { id: a.Guid, name: a.Name, checked: false };
    });

    this.contractList = this.localContractService.getItems();

    const selectContract = this.contractList.find((d) => {
      return d.Guid === urlLinkId;
    });

    this.oldContract = new Contract(selectContract);

    if (this.oldContract && this.oldContract.Code) {
      const withBoroughCode = this.oldContract.Code.split("-");

      if (withBoroughCode.length > 1) {
        this.code = withBoroughCode[1];
      }
    }

    this.ntpDate = this.oldContract.NTPDate ? new Date(this.getDateWithOffSet(this.oldContract.NTPDate)) : null;

    if (this.oldContract.ContractApplications.length > 0) {
      this.oldContract.ContractApplications.forEach((ca) => {
        const index = this.applicationList.findIndex((a) => {
          return a.id === ca.ApplicationId;
        });

        if (index > -1) {
          this.applicationList[index].checked = true;
        }
      });
    }

    this.contract = JSON.parse(JSON.stringify(this.oldContract));
    this.loadingService.stop();
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    this.checkExist();
    this.checkCodeExist();
    this.checkCodeValid();
    const validation = this.IsValid();

    if (!validation || this.contractExist || this.codeExist || !this.codeValid) {
      return;
    }

    this.update();
  }

  update(): void {
    this.loadingService.start();

    const selectedBorough = this.boroughs.find((b) => {
      return b.Guid === this.contract.BoroughId;
    });

    if (selectedBorough) {
      this.contract.Code = `${selectedBorough.Code}-${this.code}`;
      this.contract.Code = this.contract.Code.toUpperCase();
    } else {
      this.contract.Code = null;
    }

    this.contract.ContractApplications = this.contract.ContractApplications.filter((ca) => {
      return (
        this.applicationList.findIndex((a) => {
          return a.id === ca.ApplicationId && a.checked === true;
        }) !== -1
      );
    });

    this.contract = recursiveDelete(this.contract);

    this.applicationList.forEach((a) => {
      if (
        a.checked === true &&
        this.contract.ContractApplications.findIndex((ca) => {
          return ca.ApplicationId === a.id;
        }) === -1
      ) {
        this.contract.ContractApplications.push({ ApplicationId: a.id });
      }
    });

    console.log("contract", this.contract);

    this.contractService.updateContract(this.contract).subscribe(
      (r) => {
        this.loadingService.stop();
        this.router.navigateByUrl("contracts");
      },
      (error: HttpErrorResponse) => {
        this.errorHandle(error);
      }
    );
  }

  IsValid(): boolean {
    if (
      !this.contract.Name ||
      this.contract.Name.trim() === "" ||
      !this.contract.NTPDate ||
      !this.contract.BoroughId ||
      !this.code ||
      this.code.trim() === "" ||
      !this.contract.FMSID ||
      this.contract.FMSID.trim() === ""
    ) {
      return false;
    }

    return true;
  }

  checkExist(): void {
    const name = this.contract.Name;

    let withoutOld = this.contractList.filter((d) => {
      return d.InActive !== true;
    });

    if (this.oldContract.Name) {
      withoutOld = withoutOld.filter((d) => {
        return d.Name.toUpperCase() !== this.oldContract.Name.toUpperCase();
      });
    }

    if (
      name &&
      withoutOld.filter((d) => {
        return this.contract.BoroughId === d.BoroughId && d.Name.toUpperCase() === name.toUpperCase();
      }).length > 0
    ) {
      this.contractExist = true;
    } else {
      this.contractExist = false;
    }
  }

  checkCodeExist(): void {
    const code = this.code;

    let withoutOld = this.contractList.filter((d) => {
      return d.InActive !== true;
    });

    const oldBorough = this.boroughs.find((b) => {
      return b.Guid === this.oldContract.BoroughId;
    });

    if (oldBorough && this.oldContract.Code) {
      const oldCode = `${this.oldContract.Code ?? ""}`.toUpperCase();

      withoutOld = withoutOld.filter((o) => {
        return o.Code !== oldCode;
      });
    }

    const selectBorough = this.boroughs.find((b) => {
      return b.Guid === this.contract.BoroughId;
    });

    if (selectBorough) {
      const currentCode = `${selectBorough.Code}-${this.code}`.toUpperCase();

      if (
        code &&
        withoutOld.filter((d) => {
          return this.contract.BoroughId === d.BoroughId && d.Code.toUpperCase() === currentCode.toUpperCase();
        }).length > 0
      ) {
        this.codeExist = true;
      } else {
        this.codeExist = false;
      }
    } else {
      this.codeExist = false;
    }
  }

  cancel(): void {
    this.router.navigateByUrl("/contracts");
  }

  // eslint-disable-next-line class-methods-use-this
  getDateWithOffSet(date: string | null): string {
    if (!date) return null;

    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));

    return tempDate.toLocaleDateString();
  }

  setBorough(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.contract.BoroughId = e?.id;
    }
  }

  setDate(e: Date): void {
    this.ntpDate = new Date(e);
    this.contract.NTPDate = new Date(e).toISOString();
  }

  setInActive(e: boolean): void {
    this.contract.InActive = e;
  }

  setName(e: string): void {
    this.contract.Name = e;
    this.checkExist();
  }

  setCode(e: string): void {
    this.code = e;
    this.checkCodeExist();
    this.checkCodeValid();
  }

  checkCodeValid(): void {
    if (this.code.includes("-") || this.code.length > 5) {
      this.codeValid = false;
    } else {
      this.codeValid = true;
    }
  }

  errorHandle(error: HttpErrorResponse): void {
    console.error("error", error);
    this.loadingService.stop();

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Your Changes Can't be Saved.";
    modalInstance.instance.body = "Error occurs. Please refresh your screen to see updates.";

    modalInstance.result.then((result) => {
      if (result === 0) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl("blank").then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      }
    });
  }
}
