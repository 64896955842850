import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import IContractUserWithUserInfo from "../models/IContractUserWithUserInfo";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root"
})
export default class LocalContractUserService extends BaseService<IContractUserWithUserInfo> {
  public currentUserContractRole: string;

  public defaultDoc: string | null;

  protected contractUsersSubject = new BehaviorSubject<IContractUserWithUserInfo[]>(null);

  contractUsers$: Observable<IContractUserWithUserInfo[]> = this.contractUsersSubject.asObservable();

  getItems(): IContractUserWithUserInfo[] {
    return this.contractUsersSubject.getValue();
  }

  public setItems(item: IContractUserWithUserInfo[]): void {
    this.contractUsersSubject.next(item);
  }

  public getUserById(userId: string): IContractUserWithUserInfo {
    return this.getItems().filter((user: IContractUserWithUserInfo) => {
      return user.UserId == userId;
    })[0];
  }
}
