import ContractUser from "./contractUser";

export class User {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public organization: string,
    public company: string,
    public track: string,
    public contract: string,
    public checked: boolean,
    public department: string,
    public accountEnable: string,
  ) {}
}

export class UserInformation {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public organization: string,
    public company: string,
    public track: string,
    public jobTitle: string,
    public jobManager: string,
    public status: string,
    public contractList: ContractUser[],
  ) {}
}
