import { ContractApplication } from "../api-generated/model/contractApplication";
import { IPContractApplicationCreateItem } from "../api-generated/model/iPContractApplicationCreateItem";

export default class ContractApplicationCreate
  implements IPContractApplicationCreateItem
{
  public ApplicationId: string;

  public Guid?: string;

  public Sequence?: number;

  constructor(item: ContractApplication) {
    this.ApplicationId = item.ApplicationId;
    this.Guid = item.Guid;
    this.Sequence = item.Sequence;
  }
}
