export default class ContractUser {
  constructor(
    public UserId: string,
    public ContractId: string,
    public ContractRole?: string,
    public RFIRole?: string,
    public SubmittalRole?: string,
    public CorrespondenceRole?: string,
    public TransmittalRole?: string,
    public DateUpdated?: string,
    public DateCreated?: string,
    public Guid?: string,
    public DateDeleted?: string,
    public DeletedBy?: string,
  ) {
    this.UserId = UserId;
    this.ContractId = ContractId;
    this.ContractRole = ContractRole;
    this.RFIRole = RFIRole;
    this.SubmittalRole = SubmittalRole;
    this.CorrespondenceRole = CorrespondenceRole;
    this.TransmittalRole = TransmittalRole;
    this.DateUpdated = DateUpdated;
    this.DateCreated = DateCreated;
    this.Guid = Guid;
    this.DateDeleted = DateDeleted;
    this.DeletedBy = DeletedBy;
  }
}
