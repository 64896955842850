import { ISelectItem } from '@aecom/core';

export default class RowItem implements ISelectItem {
  constructor(
    public checked: boolean,
    public id: string,
    public name: string,
    public icon: string,
    public borough: string,
    public modules: string,
    public numberOfUsers: string,
    public status: string,
  ) {}
}
