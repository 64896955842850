<div class="maincontainer">
  <div class="w-100 headerSection">
    <span *ngIf="contract" class="headerTitle">
      {{ contract.Name }} Permission
    </span>

    <div class="btn bg-blue ml-auto headerCloseBtn" (click)="cancel()">
      <i class="icon icon-black icon-xl icon-action_remove"></i>
    </div>
  </div>

  <div class="contentcontainer">
    <section class="w-100 formSection">
      <ng-container *ngIf="!multipleUsers">
        <div class="row w-100 mx-0 py-2 form-head">
          <span class="form-userName">{{ selectedUsers[0].name }}</span>
        </div>

        <div class="pt-3 w-100">
          <div class="row w-100 pb-2">
            <div class="col-sm-4">
              <span class="userInfo-label">User Email</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">{{ selectedUsers[0].email }}</span>
            </div>
          </div>

          <div class="row w-100 py-2">
            <div class="col-sm-4">
              <span class="userInfo-label">Company</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">{{ selectedUsers[0].company }}</span>
            </div>
          </div>

          <div class="row w-100 py-2">
            <div class="col-sm-4">
              <span class="userInfo-label">Organization</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">
                {{ selectedUsers[0].organizations }}
              </span>
            </div>
          </div>

          <div class="row w-100 py-2">
            <div class="col-sm-4">
              <span class="userInfo-label">Track</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">{{ selectedUsers[0].track }}</span>
            </div>
          </div>

          <div class="row w-100 py-2">
            <div class="col-sm-4">
              <span class="userInfo-label">Job Title</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">{{ selectedUsers[0].jobTitle }}</span>
            </div>
          </div>

          <div class="row w-100 pt-2">
            <div class="col-sm-4">
              <span class="userInfo-label">Manager</span>
            </div>

            <div class="col-sm-8">
              <span class="userInfo-text">{{ selectedUsers[0].manager }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="multipleUsers" class="mt-5">
        <span class="subHeaderTitle">
          <span class="subHeaderNumber">
            {{ selectedUsers.length }}
          </span>
          {{ selectedUsers.length === 1 ? "User" : "Users" }} Selected
        </span>
      </div>

      <ng-container *ngIf="!disabledContract">
        <div class="row w-100 mx-0 py-2 form-head mt-5">
          <span class="form-sectionName">Contract Permission</span>
        </div>

        <div class="row w-100 mx-0 py-2">
          <div class="roleBlock">
            <aecom-button
              [innerClass]="'btn btn-icon clear md'"
              [icon]="'icon icon-action_remove_circle icon-lg icon-orange'"
              (click)="removeUser()"
            ></aecom-button>

            <div style="flex: 1; margin-right: 8px">
              <aecom-textbox
                [disabled]="true"
                [readonly]="true"
                [theme]="'light'"
                [ngValue]="contract.Name"
              ></aecom-textbox>
            </div>

            <div class="ml-auto roleDropDown">
              <aecom-dropdown
                [theme]="'light'"
                [placeholder]="'Select Role'"
                [data]="contractRoles"
                (ngValueChange)="onContractRoleChange($event)"
                [selectedId]="selectContractRole"
              ></aecom-dropdown>
            </div>
          </div>
        </div>

        <div class="row w-100 mx-0 py-2 form-head mt-5">
          <span class="form-sectionName">Module Permission</span>
        </div>

        <div class="row w-100 mx-0 py-2">
          <div
            class="roleBlock"
            *ngFor="let app of applicationList; let i = index"
          >
            <div class="roleLabel py-2">
              <span>{{ app.name }} Role</span>
            </div>

            <div class="ml-auto roleDropDown">
              <aecom-dropdown
                [theme]="'light'"
                [placeholder]="'Select Role'"
                [data]="app.dropdowns"
                (ngValueChange)="onRoleChange($event, i)"
                [selectedId]="app.value"
              ></aecom-dropdown>
            </div>
          </div></div
      ></ng-container>
    </section>

    <section class="buttonSection">
      <div class="d-flex row mx-0 pt-2" style="line-height: 48px">
        <div class="ml-auto" style="display: flex; align-items: center">
          <aecom-button
            innerClass="btn orange btn-icon outline ml-auto"
            class="px-2"
            (click)="cancel()"
          >
            Cancel
          </aecom-button>

          <!-- <aecom-button *ngIf="contract"  innerClass="btn orange btn-icon solid" class="px-2" icon="icon-social_user_ban" (click)="disable()"> Disable </aecom-button> -->
          <aecom-button
            id="saveButton"
            [disabled]="!activeSave"
            [innerClass]="
              activeSave
                ? 'btn blue btn-icon solid'
                : 'btn bg-grey-d btn-icon solid disabled'
            "
            [icon]="
              activeSave
                ? 'icon icon-black icon-basic_save'
                : 'icon icon-warm-grey icon-basic_save'
            "
            class="px-2"
            (click)="onSubmit()"
          >
            <span [ngStyle]="{ color: activeSave ? 'black' : '#8c8279' }">
              Save
            </span>
          </aecom-button>
          <!-- <aecom-button *ngIf="!contract" innerClass="btn blue btn-icon solid" class="px-2" (click)="contractEditForm.ngSubmit.emit()"> Add Contract </aecom-button> -->
        </div>
      </div>
    </section>
  </div>
</div>
