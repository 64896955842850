import { ThemeService } from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "./content/auth/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export default class AppComponent implements OnInit {
  mainHeight: number;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.mainHeight = window.innerHeight;
  }

  constructor(
    public router: Router,
    public authService: AuthService,
    public themeService: ThemeService,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.themeService.setActiveFullTheme("light");
    this.mainHeight = window.innerHeight;
  }
}
