import TimeStamp from "./timeStamp";

export default class TimeStampWithDraft extends TimeStamp {
  public IsDraft?: boolean;

  constructor(item?: TimeStampWithDraft) {
    super(item);
    this.IsDraft = item?.IsDraft;
  }
}
