/* eslint-disable import/prefer-default-export */
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ContractUserService, UserService } from "../api-generated";
import AuthService from "../content/auth/auth.service";
import IContractUserWithUserInfo from "../models/IContractUserWithUserInfo";
import LoadingService from "../services/loading.service";
import LocalContractUserService from "../services/local-contractUser.service";
import LocalUserService from "../services/local-user.service";
import LocalUserRoleService from "../services/local-userRole.service";
import { globalAdmin } from "../shared/staticValue";

@Injectable({
  providedIn: "root",
})
export class UserListResolver
  implements Resolve<IContractUserWithUserInfo[] | void>
{
  constructor(
    private contractUserService: ContractUserService,
    public localUserService: LocalUserService,
    private userService: UserService,
    public localUserRoleService: LocalUserRoleService,
    public localContractUserService: LocalContractUserService,
    public router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {}

  resolve(): Observable<IContractUserWithUserInfo[] | void> {
    this.loadingService.start();

    if (this.authService.user.Permissions.includes(globalAdmin)) {
      const localUsers = this.localUserService.getItems();
      return forkJoin([
        this.userService.getRoles(),
        this.contractUserService.getAllContractUser(),
        localUsers && localUsers.length > 0
          ? Promise.resolve(localUsers)
          : this.userService.getAll('old'),
      ]).pipe(
        map(([roles, contractUser, users]) => {
          if (!localUsers || localUsers.length === 0) {
            this.localUserService.setItems(users);
          }
          this.localContractUserService.setItems(contractUser);
          this.localUserRoleService.setItems(roles);
          return contractUser.map((cu) => {
            const user = users.find((u) => {
              return u.id === cu.UserId;
            });
            const role = roles.find((r) => {
              return (
                r.azureId === cu.UserId &&
                r.appRole.includes(cu.contract?.Code ?? "unknow")
              );
            });
            let contractRole: string;
            if (role) {
              const roleArray = role.appRole.split("_");
              contractRole = roleArray.pop();
            }
            return { ...cu, UserInfo: user, ContractRole: contractRole };
          });
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          // this.router.navigate(["error", error.status ? error.status : 500]);
          return of(null);
        }),
      );
    }
    this.router.navigateByUrl("/contracts");
    return of(null);
  }
}
