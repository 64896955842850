import { IPContractUserApplicationRoleCreateItem } from "../api-generated/model/iPContractUserApplicationRoleCreateItem";
import { IPContractUserCreateItem } from "../api-generated/model/iPContractUserCreateItem";

export default class ContractUserCreateItem
  implements IPContractUserCreateItem
{
  constructor(
    public UserId: string,
    public ContractId: string,
    public ContractRole: string,
    public ApplicationRoles: IPContractUserApplicationRoleCreateItem[],
    public Guid?: string,
  ) {}
}
