/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import {
  FilterPipe,
  IMenuItem,
  ISelectItem,
  ITableFilter,
  ModalContainerService,
  ModalRef,
  NotificationComponent
} from "@aecom/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Borough } from "src/app/api-generated/model/borough";
import { TPApplicationRole } from "src/app/api-generated/model/tPApplicationRole";
import { TPContract } from "src/app/api-generated/model/tPContract";
import ColumnType from "src/app/models/columnType";
import IContractUserWithUserInfo from "src/app/models/IContractUserWithUserInfo";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationRoleService from "src/app/services/local-applicationRole.service";
import LocalBoroughService from "src/app/services/local-borough.service";
import LocalContractService from "src/app/services/local-contract.service";
import RouteParams from "src/app/shared/route-params";
import { DB_Admin, DB_User, NoAccess, correspondenceAppId, rfiAppId, submittalAppId, transmittalAppId } from "src/app/shared/staticValue";
import AuthService from "../../auth/auth.service";
import TableHeaderCol from "./table-header-col";
import RowItem from "./table-row";

@Component({
  selector: "app-contractUser-summary",
  templateUrl: "./contractUser-summary.component.html",
  styleUrls: ["./contractUser-summary.component.css"]
})
export default class ContractUserSummaryComponent implements OnInit {
  public selectContract: TPContract;

  public selectBorough: Borough;

  private inittableData: RowItem[] = [];

  public tableData: RowItem[] = [];

  public tableRows: RowItem[] = [];

  selectedUsers: RowItem[] = [];

  public tableHeader: TableHeaderCol[] = [
    {
      Name: "id",
      Type: "hide",
      Header: "Guid",
      RelativeWidth: 0
    },
    {
      Name: "name",
      Type: "text",
      Header: "Name",
      RelativeWidth: 100
    },
    {
      Name: "email",
      Type: "text",
      Header: "Email",
      RelativeWidth: 150
    },
    {
      Name: "organizations",
      Type: "text",
      Header: "Organizations",
      RelativeWidth: 100
    },
    {
      Name: "track",
      Type: "text",
      Header: "Track",
      RelativeWidth: 50
    },
    {
      Name: "contract",
      Type: "text",
      Header: "Contract",
      RelativeWidth: 50
    },
    {
      Name: "rfi",
      Type: "text",
      Header: "RFI",
      RelativeWidth: 50
    },
    {
      Name: "submittal",
      Type: "text",
      Header: "Submittal",
      RelativeWidth: 50
    },
    {
      Name: "correspondence",
      Type: "text",
      Header: "Correspondence",
      RelativeWidth: 50
    },
    {
      Name: "transmittal",
      Type: "text",
      Header: "Transmittal",
      RelativeWidth: 50
    }
  ];

  public columnFilters: ITableFilter[] = [
    {
      column: "borough",
      name: "Borough",
      multiselect: false
    },
    {
      column: "status",
      name: "Status",
      multiselect: false
    }
  ];

  allFilters: ISelectItem[] = [];

  searchText = "";

  organizations: ISelectItem[] = [];

  roleList: TPApplicationRole[] = [];

  private selectedOrganization: string = null;

  roles: ISelectItem[] = [];

  private selectedRole: string = null;

  modules: ISelectItem[] = [];

  private selectedModule = null;

  public selectedNumber = "";

  public showNoItem = false;

  public showEditContract = false;

  public showAssignUser = false;

  public showManageUsers = false;

  private firstOpen = true;

  public activeSave = false;

  columnType = ContractUserSummaryComponent.columnType;

  getBoroughIcon = ContractUserSummaryComponent.getBoroughIcon;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    public authService: AuthService,
    private contractService: LocalContractService,
    private boroughService: LocalBoroughService,
    private applicationRoleService: LocalApplicationRoleService,
    private activatedRoute: ActivatedRoute,
    private activeModal: ModalContainerService
  ) {}

  static get columnType(): typeof ColumnType {
    return ColumnType;
  }

  static getBoroughIcon(icon: string): string {
    return `assets/icons/${icon}`;
  }

  ngOnInit(): void {
    const contractUsers: IContractUserWithUserInfo[] = this.activatedRoute.snapshot.data.contractUserData;

    const urlLinkId = this.activatedRoute.snapshot.params[RouteParams.ItemId];
    const contractList = this.contractService.getItems();

    this.roleList = this.applicationRoleService.getItems();

    this.selectContract =
      contractList.filter((d) => {
        return d.Guid === urlLinkId;
      })[0] ?? null;

    if (!this.selectContract) {
      this.router.navigateByUrl("/contracts");
    }

    const boroughList = this.boroughService.getItems();

    this.selectBorough =
      boroughList.filter((b) => {
        return b.Code.toUpperCase() === this.selectContract.borough.Code.toUpperCase();
      })[0] ?? null;

    this.initContractUserViewModel(contractUsers);
    this.loadingService.stop();
  }

  initContractUserViewModel(list: IContractUserWithUserInfo[]): void {
    const contractUsers = list.sort((a, b) => new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime());

    contractUsers.forEach((value) => {
      let organization = "";

      if (value.UserInfo?.department) {
        const nameArray = value.UserInfo.department.split(" ");

        if (nameArray) {
          [organization] = nameArray;
        }
      }

      const rfiRole = value.contract_user_application_role.find((r) => r.ApplicationId === rfiAppId)?.ApplicationRoleId;

      const submittalRole = value.contract_user_application_role.find(
        (r) => r.ApplicationId === submittalAppId
      )?.ApplicationRoleId;

      const correspondenceRole = value.contract_user_application_role.find(
        (r) => r.ApplicationId === correspondenceAppId
      )?.ApplicationRoleId;

      const transmittalRole = value.contract_user_application_role.find(
        (r) => r.ApplicationId === transmittalAppId
      )?.ApplicationRoleId;

      this.inittableData.push(
        new RowItem(
          false,
          value.Guid,
          value.UserInfo?.displayName ?? "",
          value.UserInfo?.mail ?? "",
          organization,
          value.UserInfo?.department ?? "",
          value.ContractRole ?? "",
          "",
          value.UserInfo?.jobTitle ?? "",
          value.UserInfo?.companyName ?? "",
          value.UserId,
          (this.roleList.find((r) => r.Guid === rfiRole)?.Role || NoAccess).replace(DB_Admin,DB_User),
          (this.roleList.find((r) => r.Guid === submittalRole)?.Role || NoAccess).replace(DB_Admin,DB_User),
          (this.roleList.find((r) => r.Guid === correspondenceRole)?.Role || NoAccess).replace(DB_Admin,DB_User),
          (this.roleList.find((r) => r.Guid === transmittalRole)?.Role || NoAccess).replace(DB_Admin,DB_User)
        )
      );
    });

    this.tableData = [...this.inittableData];

    console.log(this.tableData);

    if (this.inittableData.length) {
      let organizationList = this.inittableData.map((row) => row.organizations);

      organizationList = [...new Set(organizationList)];
      organizationList.sort();

      this.organizations = organizationList.map((o) => {
        return { id: o, name: o, checked: false };
      });

      const contractRoleList = this.inittableData.map((row) => row.contract);
      const rfiRoleList = this.inittableData.map((row) => row.rfi);
      const submittalRoleList = this.inittableData.map((row) => row.submittal);

      let roleList = contractRoleList.concat(rfiRoleList, submittalRoleList);

      roleList = [...new Set(roleList)].filter((row) => row.trim() !== "");
      roleList.sort();

      this.roles = roleList.map((o) => {
        return { id: o, name: o, checked: false };
      });

      this.modules = [
        { id: "rfi", name: "RFI", checked: false },
        { id: "submittal", name: "Submittal", checked: false }
      ];
    } else {
      this.showNoItem = true;
    }
  }

  updateViewModel(): void {
    let result = this.searchText
      ? this.inittableData.filter((i) => FilterPipe.contains(i, this.searchText))
      : this.inittableData;

    if (this.selectedOrganization && this.selectedOrganization !== "") {
      result = result.filter((item) => {
        return item.organizations === this.selectedOrganization;
      });
    }

    if (this.selectedRole && this.selectedRole !== "") {
      result = result.filter((item) => {
        return (
          item.contract === this.selectedRole || item.rfi === this.selectedRole || item.submittal === this.selectedRole
        );
      });
    }

    if (this.selectedModule && this.selectedModule !== "") {
      result = result.filter((item) => {
        return item[this.selectedModule] !== "";
      });
    }

    this.tableData = [...result];
  }

  moduleSelect(e: IMenuItem | IMenuItem[]): void {
    if (e) {
      this.selectedModule = Array.isArray(e) ? "" : e.id;
      this.updateViewModel();
    }
  }

  roleSelect(e: IMenuItem | IMenuItem[]): void {
    if (e) {
      this.selectedRole = Array.isArray(e) ? "" : e.name;
      this.updateViewModel();
    }
  }

  organizationSelect(e: IMenuItem | IMenuItem[]): void {
    if (e) {
      this.selectedOrganization = Array.isArray(e) ? "" : e.name;
      this.updateViewModel();
    }
  }

  assignUserButtonClick(): void {
    this.activeSave = false;
    this.showAssignUser = true;
  }

  backButtonClick(): void {
    this.router.navigateByUrl("/contracts");
  }

  settingButtonClick(): void {
    // this.router.navigateByUrl('/email');
    this.activeSave = false;
    this.showEditContract = true;
  }

  manageButtonClick(): void {
    // this.router.navigated = false;
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    // this.router.navigate([`/contractUser/${this.selectContract.Guid}`]));
    if (this.selectedNumber !== "") {
      this.selectedUsers = this.tableData.filter((row) => row.checked);
      this.activeSave = false;
      this.showManageUsers = true;
    }
  }

  userSettingClick(row: RowItem): void {
    this.selectedUsers = [row];
    this.activeSave = false;
    this.showManageUsers = true;
  }

  checkBoxChange(): void {
    const count = this.tableData.filter((row) => row.checked).length;
    this.selectedNumber = count === 0 ? "" : `(${count})`;
  }

  closeEditContractWindow(): void {
    if (this.firstOpen) {
      this.firstOpen = false;
    } else if (!this.activeSave) {
      this.firstOpen = true;
      this.showEditContract = false;
    } else {
      const modalInstance = this.createModal();

      modalInstance.result.then(async (result) => {
        this.firstOpen = true;

        if (result === 1) {
          this.showEditContract = false;
        }
      });
    }
  }

  closeAssignUserWindow(): void {
    if (this.firstOpen) {
      this.firstOpen = false;
    } else if (!this.activeSave) {
      this.firstOpen = true;
      this.showAssignUser = false;
    } else {
      const modalInstance = this.createModal();

      modalInstance.result.then(async (result) => {
        this.firstOpen = true;

        if (result === 1) {
          this.showAssignUser = false;
        }
      });
    }
  }

  closeManageUsersWindow(): void {
    if (this.firstOpen) {
      this.firstOpen = false;
    } else if (!this.activeSave) {
      this.firstOpen = true;
      this.showManageUsers = false;
    } else {
      const modalInstance = this.createModal();

      modalInstance.result.then(async (result) => {
        this.firstOpen = true;

        if (result === 1) {
          this.showManageUsers = false;
        }
      });
    }
  }

  createModal(): ModalRef<NotificationComponent> {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.title = "Notification";
    modalInstance.instance.body = "Are you sure you would like to cancel?";
    modalInstance.instance.theme = "light";
    return modalInstance;
  }
}
