
import { AecomCoreModule, LayoutService } from "@aecom/core";
import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { NgxSpinnerModule } from "ngx-spinner";
import environment from "src/environments/environment";
import { OAuthSettings } from "../oauth";
import { BASE_PATH } from "./api-generated/variables";
import AppRoutingModule from "./app-routing.module";
import AppComponent from "./app.component";
import AccessDeniedComponent from "./content/auth/access-denied/access-denied.component";
import AuthInterceptor from "./content/auth/auth.interceptor";
import AuthService from "./content/auth/auth.service";
import BaseComponent from "./content/base/base.component";
import ContractAssignUserComponent from "./content/contract/contract-assignUser/contract-assignUser.component";
import ContractCreateComponent from "./content/contract/contract-create/contract-create.component";
import ContractEditComponent from "./content/contract/contract-edit/contract-edit.component";
import ContractManageUserComponent from "./content/contract/contract-manageUser/contract-manageUser.component";
import ContractSummaryComponent from "./content/contract/contract-summary/contract-summary.component";
import ContractUserSummaryComponent from "./content/contract/contractUser-summary/contractUser-summary.component";
import ErrorComponent from "./content/error/error.component";
import SingoutComponent from "./content/singout/singout.component";
import UserListComponent from "./content/user/user-list/user-list.component";
import ContractResolver from "./resolvers/contract-resolver";
import ContractUserResolver from "./resolvers/contractUser-resolver";
import ErrorResolve from "./resolvers/error-resolver";
import LoginUserResolve from "./resolvers/login-user-resolver";
import { UserListResolver } from "./resolvers/user-list.resolver";
import LocalApplicationService from "./services/local-application.service";
import LocalApplicationRoleService from "./services/local-applicationRole.service";
import LocalBoroughService from "./services/local-borough.service";
import LocalContractService from "./services/local-contract.service";
import LocalContractUserService from "./services/local-contractUser.service";
import LocalModuleService from "./services/local-module.service";
import LocalStorageService from "./services/local-storage.service";
import LocalUserService from "./services/local-user.service";
import NavService from "./services/nav.service";
import { StorageKey } from "./shared/localstorage";
// import { ApiModule } from '@api-generated/api.module'

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false, // set to true for IE 11
    },
  });
}

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false, // set to true for IE 11
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
    // unprotectedResources: OAuthSettings.unprotectedResources,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}
@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    BaseComponent,
    ContractSummaryComponent,
    ContractAssignUserComponent,
    ContractEditComponent,
    ContractCreateComponent,
    ContractUserSummaryComponent,
    ContractManageUserComponent,
    ErrorComponent,
    UserListComponent,
    SingoutComponent,
  ],
  imports: [
    AecomCoreModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    // DndModule.forRoot(),
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
    BrowserAnimationsModule,
    // UserEditComponentsModule,
    // NgxMaskModule.forRoot(options),
    // ApiModule
  ],
  providers: [
    DatePipe,
    LocalStorageService,
    LocalContractService,
    LocalContractUserService,
    LocalUserService,
    NavService,
    LocalApplicationService,
    LocalApplicationRoleService,
    LocalBoroughService,
    LocalModuleService,
    LoginUserResolve,
    ContractResolver,
    ContractUserResolver,
    UserListResolver,
    ErrorResolve,
    AuthService,
    ContractUserResolver,
    LayoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
