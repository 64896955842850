<div class="maincontainer">
  <div class="w-100 headerSection">
    <span class="headerTitle">Contract Information</span>

    <div class="btn bg-blue ml-auto headerCloseBtn" (click)="cancel()">
      <i class="icon icon-black icon-xl icon-action_remove"></i>
    </div>
  </div>

  <div class="contentcontainer">
    <section class="w-100 formSection">
      <form
        [formGroup]="contractForm"
        (ngSubmit)="onSubmit()"
        id="ngForm"
        class="row mx-0 w-100"
      >
        <div class="row w-100 mx-0 py-2 form-head">
          <span>Contract Information</span>
        </div>

        <div class="pb-2 pt-3 w-100">
          <aecom-form-item [label]="'Contract Title'">
            <aecom-textbox
              class="contractName"
              [theme]="'light'"
              [ngValue]="f.name.value"
              (ngValueChange)="onNameChange($event)"
            ></aecom-textbox>
          </aecom-form-item>

          <div
            *ngIf="submitted && (f.name.errors || contractExist)"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.name.errors">Contract Name is required</div>
            <div *ngIf="contractExist">Contract Name is Exist</div>
          </div>
        </div>

        <div class="py-3 w-100">
          <aecom-form-item [label]="'Borough'">
            <aecom-dropdown
              class="borough"
              [theme]="'light'"
              [placeholder]="'Select Borough'"
              [data]="boroughList"
              (ngValueChange)="onBoroughChange($event)"
              [selectedId]="f.borough.value"
            ></aecom-dropdown>
          </aecom-form-item>

          <div *ngIf="submitted && f.borough.errors" class="invalid-feedback">
            <div *ngIf="f.borough.errors.required">Borough is required</div>
          </div>
        </div>

        <div class="pt-2 pb-3 w-100">
          <aecom-form-item [label]="'PIN Number (Optional)'">
            <aecom-textbox
              [theme]="'light'"
              [ngValue]="f.pin.value"
              (ngValueChange)="
                contractForm.patchValue({ pin: $event });
                activeSaveChange.emit(true)
              "
            ></aecom-textbox>
          </aecom-form-item>
        </div>

        <div class="row w-100 mx-0 py-2 form-head">
          <span>Module Access</span>
        </div>

        <div
          *ngFor="let app of appsArray.controls; let i = index"
          class="w-100 pt-2"
        >
          <div class="col-sm px-0">
            <aecom-checkbox
              [ngValue]="app.value"
              (ngValueChange)="onCheckBoxChange(i, $event)"
              theme="light"
            >
              {{ applicationList[i].Name }}
            </aecom-checkbox>
          </div>
        </div>

        <div class="row w-100 mx-0 py-2 form-head">
          <span>Contract Status</span>
        </div>

        <div class="w-100 pt-4">
          <aecom-dropdown
            [theme]="'light'"
            [placeholder]="'Select Status'"
            [data]="statusList"
            (ngValueChange)="onStatusChange($event)"
            [selectedId]="f.disabled.value === false ? 'active' : 'disable'"
          ></aecom-dropdown>
        </div>
      </form>
    </section>

    <section class="buttonSection">
      <div class="d-flex row mx-0 pt-2" style="line-height: 48px">
        <div class="ml-auto" style="display: flex; align-items: center">
          <aecom-button
            innerClass="btn orange btn-icon outline ml-auto"
            class="px-2"
            (click)="cancel()"
          >
            Cancel
          </aecom-button>

          <!-- <aecom-button *ngIf="contract"  innerClass="btn orange btn-icon solid" class="px-2" icon="icon-social_user_ban" (click)="disable()"> Disable </aecom-button> -->

          <aecom-button
            id="saveButton"
            [disabled]="!activeSave"
            [innerClass]="
              activeSave
                ? 'btn blue btn-icon solid'
                : 'btn bg-grey-d btn-icon solid disabled'
            "
            class="px-2"
            [icon]="
              activeSave
                ? 'icon icon-black icon-basic_save'
                : 'icon icon-warm-grey icon-basic_save'
            "
            (click)="onSubmit()"
          >
            <span [ngStyle]="{ color: activeSave ? 'black' : '#8c8279' }">
              Save
            </span>
          </aecom-button>
          <!-- <aecom-button *ngIf="!contract" innerClass="btn blue btn-icon solid" class="px-2" (click)="contractEditForm.ngSubmit.emit()"> Add Contract </aecom-button> -->
        </div>
      </div>
    </section>
  </div>
</div>
