<div class="maincontainer light-theme">
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1 class="title">All users</h1>

          <div class="header-action">
            <aecom-button
              [disabled]="!canAssign() ? true : null"
              innerClass="btn blue btn-icon solid "
              (click)="assignUsers()"
            >
              assign to contract
            </aecom-button>
            <aecom-button style="margin-left: 16px" innerClass="btn blue btn-icon solid" (click)="resendDaildEmail()">
              resend daily reminder email
            </aecom-button>
            <aecom-button style="margin-left: 16px" innerClass="btn blue btn-icon solid" (click)="onboardUser()">
              onboard user
            </aecom-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list cus"
          [canSearch]="true"
          [columnFilters]="columnFilters"
        >
          <thead>
            <tr>
              <th class="checkbox">
                <aecom-column-selector></aecom-column-selector>
              </th>

              <ng-container *ngFor="let col of tableHeader">
                <th
                  *ngIf="col.Type !== columnType.Hide"
                  [ngClass]="col.Name === 'edit' ? '' : 'sort'"
                  id="{{ col.Name }}"
                >
                  {{ col.Header }}
                </th>
              </ng-container>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableRows" [class]="row.checked ? 'selected' : ''">
              <td class="checkbox">
                <aecom-checkbox [(ngValue)]="row.checked"></aecom-checkbox>
              </td>

              <ng-container *ngFor="let col of tableHeader">
                <td *ngIf="col.Type !== columnType.Hide">
                  {{ row[col.Name] }}
                </td>
              </ng-container>

              <td>
                <a class="btn" (click)="assignUser(row.id)">
                  <i class="icon icon-basic_gear icon-blue icon-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </aecom-table>
      </div>
    </div>
  </section>

  <aside class="right-nav" *ngIf="assignSingleUser">
    <!-- <div class="nav-wrapper" (clickOutside)="cancel()"> -->
    <div class="nav-wrapper">
      <div>
        <header>
          <h1>User information</h1>

          <aecom-button
            innerClass="btn bg-blue ml-auto headerCloseBtn"
            [icon]="'icon icon-black icon-xl icon-action_remove'"
            (click)="cancel()"
          ></aecom-button>
        </header>

        <main>
          <section class="user-information">
            <h2 class="main-title">{{ userInformation.name }}</h2>

            <ul class="nav-list">
              <li>
                <span>User Email</span>
                <span>{{ userInformation.email }}</span>
              </li>

              <li>
                <span>Company</span>
                <span>{{ userInformation.company }}</span>
              </li>

              <li>
                <span>Organization</span>
                <span>{{ userInformation.organization }}</span>
              </li>

              <li>
                <span>Track</span>
                <span>{{ userInformation.track }}</span>
              </li>

              <li>
                <span>Job Title</span>
                <span>{{ userInformation.jobTitle }}</span>
              </li>

              <li>
                <span>Manager</span>
                <span>{{ userInformation.jobManager }}</span>
              </li>
            </ul>
          </section>

          <section class="user-permission" *ngIf="false">
            <h2 class="main-subtitle">User Permission</h2>

            <div class="user-status">
              <h4>User Status</h4>

              <aecom-dropdown
                [theme]="'light'"
                [data]="statusDropdown"
                [placeholder]="'Select Status'"
                [selectedId]="userInformation.status"
                (ngValueChange)="updateUserStatus($event)"
              ></aecom-dropdown>
            </div>
          </section>

          <section class="contract-permission">
            <h2 class="main-subtitle">Contract Permission</h2>

            <ul class="nav-list">
              <ng-container *ngFor="let contract of userInformation.contractList; let i = index">
                <li *ngIf="!contract.DateDeleted">
                  <div>
                    <aecom-button
                      *ngIf="contractListDropdown.length"
                      [innerClass]="'btn btn-icon clear md'"
                      [icon]="'icon icon-action_remove_circle icon-lg icon-orange'"
                      (click)="deleteUserContract(contract)"
                    ></aecom-button>

                    <aecom-dropdown
                      [data]="contractListDropdown"
                      [selectedId]="contract.ContractId"
                      placeholder="Select Contract"
                      [theme]="'light'"
                      (ngValueChange)="setContract(contract, $event)"
                    ></aecom-dropdown>
                  </div>

                  <aecom-dropdown
                    placeholder="Role"
                    [data]="contractRoles"
                    [selectedId]="contract.ContractRole"
                    [theme]="'light'"
                    (ngValueChange)="setContractRole(contract, $event)"
                  ></aecom-dropdown>
                </li>
              </ng-container>
            </ul>

            <aecom-button
              [innerClass]="'btn btn-icon clear '"
              [icon]="'icon-action_add_circle icon-lg icon-green'"
              (click)="addMoreContracts()"
            >
              Add more contract
            </aecom-button>
          </section>
        </main>
      </div>

      <footer>
        <div></div>

        <div>
          <aecom-button innerClass="btn orange btn-icon outline" (click)="cancel()"> cancel </aecom-button>

          <aecom-button innerClass="btn blue btn-icon solid" (click)="save()" [disabled]="!hasChanges() ? true : null">
            assign
          </aecom-button>
        </div>
      </footer>
    </div>
  </aside>

  <aside class="right-nav" *ngIf="assignMultipleUsers">
    <!-- <div class="nav-wrapper" (clickOutside)="cancel()"> -->
    <div class="nav-wrapper">
      <div>
        <header>
          <h1>Assign to contract</h1>

          <aecom-button
            innerClass="btn bg-blue ml-auto headerCloseBtn"
            [icon]="'icon icon-black icon-xl icon-action_remove'"
            (click)="cancel()"
          ></aecom-button>
        </header>

        <main>
          <section class="user-information" style="margin-bottom: 32px">
            <h2 class="main-title" style="border: none">
              <span>{{ getUsers() }}</span>
              {{ getUsers() === 1 ? "User" : "Users" }} Selected
            </h2>
          </section>

          <section class="contract-permission">
            <h2 class="main-subtitle">Assign Contract</h2>

            <ul class="nav-list">
              <li>
                <aecom-dropdown
                  [data]="contractListDropdown"
                  [selectedId]="multiUserContract.ContractId"
                  placeholder="Select Contract"
                  [theme]="'light'"
                  (ngValueChange)="setContract(multiUserContract, $event)"
                ></aecom-dropdown>

                <aecom-dropdown
                  placeholder="Role"
                  [data]="contractRoles"
                  [selectedId]="multiUserContract.ContractRole"
                  [theme]="'light'"
                  (ngValueChange)="setContractRole(multiUserContract, $event)"
                ></aecom-dropdown>
              </li>
            </ul>
          </section>
        </main>
      </div>

      <footer>
        <div></div>

        <div>
          <aecom-button innerClass="btn orange btn-icon outline" (click)="cancel()"> cancel </aecom-button>

          <aecom-button
            innerClass="btn blue btn-icon solid"
            [disabled]="!hasChanges() ? true : null"
            (click)="saveMultipleUsers()"
          >
            assign
          </aecom-button>
        </div>
      </footer>
    </div>
  </aside>
</div>
