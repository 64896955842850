import { ISelectItem } from "@aecom/core";

export default class RowItem implements ISelectItem {
  constructor(
    public checked: boolean,
    public id: string,
    public name: string,
    public email: string,
    public organizations: string,
    public track: string,
    public contract: string,
    public manager: string,
    public jobTitle: string,
    public company: string,
    public userId: string,
    public rfi?: string,
    public submittal?: string,
    public correspondence?: string,
    public transmittal?: string,
  ) {}
}
