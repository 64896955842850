import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import AuthService from "../content/auth/auth.service";
import LoadingService from "../services/loading.service";
import LoginUser from "../models/loginUser";

@Injectable()
export default class ErrorResolve {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {}

  resolve(): Observable<void | LoginUser > {
    this.loadingService.start();
    return from(this.authService.login()).pipe(
      first(),
      catchError((error: HttpErrorResponse) => {
        return of();
      }),
    );
  }
}
