<div class="maincontainer light-theme">
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header" style="align-items: flex-start !important">
          <div>
            <div class="d-flex row mx-0">
              <div class="nav-top-link">
                <a routerLink="/contracts" class="pl-0">
                  <span>Contracts</span>
                </a>

                <span class="fas fa-angle-right"></span>

                <a>
                  <span *ngIf="selectContract">{{ selectContract.Name }}</span>
                </a>
              </div>
            </div>

            <div class="d-flex row mx-0 pt-2" style="line-height: 48px">
              <img
                *ngIf="selectBorough"
                style="width: 36px; height: 36px; margin-right: 8px"
                src="{{ getBoroughIcon(selectBorough.Icon) }}"
              />

              <h1 *ngIf="selectContract" class="title">
                {{ selectContract.Name }}
              </h1>
            </div>
          </div>

          <div class="header-action">
            <a [tooltip]="'Contract Settings'" placement="bottom" class="btn pr-3" (click)="settingButtonClick()">
              <i class="icon icon-basic_gear icon-orange icon-lg"></i>
            </a>

            <aecom-button innerClass="btn orange btn-icon outline ml-auto" class="pl-3" (click)="backButtonClick()">
              Back
            </aecom-button>

            <aecom-button
              innerClass="btn blue btn-icon solid"
              class="pl-3"
              icon="icon icon-black icon-social_user_add"
              (click)="assignUserButtonClick()"
            >
              <span>Assign User</span>
            </aecom-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 table-container">
        <div class="filters-wrapper">
          <div class="filters">
            <aecom-button
              [title]="'Manage Users' + selectedNumber"
              [disabled]="selectedNumber === ''"
              innerClass="btn blue btn-icon outline ml-auto"
              class="pr-4"
              icon="icon icon-blue icon-basic_gear"
              (click)="manageButtonClick()"
            >
              Manage <span>{{ selectedNumber }}</span>
            </aecom-button>

            <div class="search">
              <aecom-textbox
                theme="light"
                [(ngValue)]="searchText"
                name="table-search"
                placeholder="Search"
                iconLeft="icon-basic_magnifier"
                (ngValueChange)="updateViewModel()"
              ></aecom-textbox>
            </div>

            <div class="dropdown">
              <aecom-dropdown
                theme="light"
                [data]="organizations"
                [placeholder]="'Select Organizations'"
                [canClear]="true"
                (ngValueChange)="organizationSelect($event)"
              ></aecom-dropdown>
            </div>

            <div class="dropdown">
              <aecom-dropdown
                theme="'light'"
                [data]="roles"
                [placeholder]="'Select Role'"
                [canClear]="true"
                (ngValueChange)="roleSelect($event)"
              ></aecom-dropdown>
            </div>

            <div class="dropdown">
              <aecom-dropdown
                [theme]="'light'"
                [data]="modules"
                [placeholder]="'Select Module'"
                [canClear]="true"
                (ngValueChange)="moduleSelect($event)"
              ></aecom-dropdown>
            </div>
          </div>
        </div>

        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          theme="'light'"
          innerClass="list cus"
          class="hideFilter"
        >
          <thead>
            <tr>
              <th class="checkbox">
                <aecom-column-selector (ngValueChange)="checkBoxChange()"></aecom-column-selector>
              </th>

              <ng-container *ngFor="let hCol of tableHeader">
                <th *ngIf="hCol.Type !== columnType.Hide" class="sort" id="{{ hCol.Name }}">
                  {{ hCol.Header }}
                </th>
              </ng-container>

              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let bRow of tableRows">
              <td class="checkbox">
                <aecom-checkbox [(ngValue)]="bRow.checked" (ngValueChange)="checkBoxChange()"></aecom-checkbox>
              </td>

              <ng-container *ngFor="let hCol of tableHeader">
                <td *ngIf="hCol.Type !== columnType.Hide">
                  <span class="tr_Item">{{ bRow[hCol.Name] }}</span>
                </td>
              </ng-container>

              <td>
                <a class="btn" (click)="userSettingClick(bRow)">
                  <i class="icon icon-basic_gear icon-blue icon-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </aecom-table>

        <div style="position: relative; top: -5px; width: 100%; background-color: white">
          <div class="noItem-body" *ngIf="showNoItem">
            <div>
              <div class="noItem-icon-row icon icon-social_user_team_big icon-blue icon-xl"></div>
            </div>

            <div class="noItem-title-row">No assigned user yet</div>
            <div class="noItem-content-row">Please assign users to your contract.</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="showEditContract || showAssignUser || showManageUsers" class="up-contentcontainer">
  <div *ngIf="showEditContract" class="editContractWindow" (clickOutside)="closeEditContractWindow()">
    <app-contract-edit
      [contract]="selectContract"
      (close)="closeEditContractWindow()"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
      [currentPath]="'summary'"
    ></app-contract-edit>
  </div>

  <div *ngIf="showAssignUser" class="assignUserWindow">
    <app-contract-assign-user
      [contract]="selectContract"
      (close)="closeAssignUserWindow()"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></app-contract-assign-user>
  </div>

  <div *ngIf="showManageUsers" class="manageUsersWindow" (clickOutside)="closeManageUsersWindow()">
    <app-contract-manage-user
      [contract]="selectContract"
      [selectedUsers]="selectedUsers"
      (close)="closeManageUsersWindow()"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></app-contract-manage-user>
  </div>
</div>
