<div class="maincontainer light-theme">
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1 class="title">Contracts</h1>

          <div class="header-action" *ngIf="isGlobalAdmin">
            <aecom-button innerClass="btn blue btn-icon solid ml-auto" class="ml-auto" (click)="createContract()">
              Add Contract
            </aecom-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          theme="'light'"
          innerClass="list cus"
          [canSearch]="true"
          [columnFilters]="columnFilters"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let hCol of tableHeader">
                <th
                  *ngIf="hCol.Type !== columnType.Hide"
                  [ngClass]="hCol.Type === columnType.BoroughIcon ? 'tablehead' : 'sort'"
                  id="{{ hCol.Name }}"
                >
                  {{ hCol.Header }}
                </th>
              </ng-container>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bRow of tableRows" class="pointer">
              <ng-container *ngFor="let hCol of tableHeader">
                <td *ngIf="hCol.Type !== columnType.Hide" (click)="contractClick(bRow)">
                  <div *ngIf="hCol.Type === columnType.BoroughIcon" style="align-items: center; display: flex">
                    <img
                      style="width: 40px; height: 40px; margin-left: 10px"
                      src="{{ getBoroughIcon(bRow[hCol.Name]) }}"
                    />
                  </div>
                  <span *ngIf="hCol.Type !== columnType.BoroughIcon" class="tr_Item">
                    {{ bRow[hCol.Name] }}
                  </span>
                </td>
              </ng-container>
              <td>
                <a class="btn" (click)="contractSettingClick(bRow)">
                  <i class="icon icon-basic_gear icon-blue icon-lg"></i>
                </a>
                <!-- <i class="icon icon-basic_gear icon-blue icon-lg"></i> -->
              </td>
            </tr>
          </tbody>
        </aecom-table>
      </div>
    </div>

    <div class="tablecontent row mx-0"></div>
  </section>
</div>

<div class="up-contentcontainer" *ngIf="showEditContract">
  <div *ngIf="showEditContract" class="editContractWindow" (clickOutside)="closeEditContractWindow()">
    <app-contract-edit
      [contract]="selectContract"
      (close)="closeEditContractWindow()"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
      [currentPath]="'contractList'"
    ></app-contract-edit>
  </div>
</div>
