import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ContractService } from "../api-generated";
import { TPContract } from "../api-generated/model/tPContract";
import LoadingService from "../services/loading.service";
import LocalContractService from "../services/local-contract.service";

@Injectable()
export default class ContractResolver implements Resolve<TPContract[] | null> {
  constructor(
    private contractService: ContractService,
    public localContractService: LocalContractService,
    private loadingService: LoadingService,
    private router: Router,
  ) {}

  resolve(): Observable<TPContract[] | null> {
    this.loadingService.start();
    return this.contractService.getAllContract().pipe(
      map((contracts) => {
        this.localContractService.setItems(contracts);
        return contracts;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        // this.router.navigate(["error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }
}
